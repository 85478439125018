.Footer-container {
    // position: fixed;
    bottom: 0;
    color: var(--rcyan);
    font-size: 13px;
    width: 100%;
    padding: calc(var(--bs-gutter-x) * .5) 0;
    justify-content: center;

    a {
        color: var(--rcyan);
        text-decoration: none;
    }

    img {
        max-width: 700px;
    }

    .col-12 {
        z-index: 20;
    }
}
