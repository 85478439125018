:root {
    --rblack: #1E1E1E;
    --rgray-darker: #2B2B2B;
    --rgray-dark: #393939;
    --rgray-light: #EFF1F2;
    --rgray-border: #C7CCD1;
    --rgray-placeholder: #9B9B9B;
    --rcyan: #00B2D2;
    --rpink: #F48DB8;
}
