.outService-container {
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    position: absolute;
    z-index: 20;
    background: var(--rgray-darker);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .outService-image-container {

        img {
            width: 207px;
            height: 80px;
            object-fit: contain;
        }
    }

    .outService-disclaimer-container {
        text-align: center;
        padding-top: 20px;
        padding-left: 14px;
        padding-right: 14px;;
        font-size: 16px;
        font-weight: bold;
        max-width: 700px;
        color: var(--rgray-placeholder);
        
    }

    .subtitle-container {
        text-align: center;
        font-size: 16px;
        max-width: 700px;
        color: var(--rgray-placeholder);
        margin-right: 100px;
        margin-left: 100px;
    }

    .blue{
        color: #00B2D2
    }


}
