$recover-purple: #7F60EA;
$rem360: 22.5rem;
$rem315: 19.6875rem;
$rem58: 3.625rem;
$rem50: 3.125rem;
$rem40: 2.5rem;
$rem36: 2.25rem;
$rem30: 1.875rem;
$rem18: 1.125rem;
$rem10: 0.625rem;

.recover-animation {
    animation: dissapear .5s ease-in;
    animation-direction: normal;

    &.show {
        animation-direction: reverse;
    }

}

@keyframes dissapear {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

.recover-container {
    background: #1E1E1E;
    position: relative;
    ;

    .recover-back-box {
        position: absolute;
        top: 0;
        left: 0;
        padding-top: 1rem;
        padding-left: 1rem;

        button {
            background: transparent;
            padding: 0;
            height: $rem40;
            border: none;
            outline: none !important;
            color: $recover-purple;
            font-size: $rem18;

            img {
                width: $rem40;
                height: $rem40;
            }
        }
    }

    .recover-main-box {
        .recover-secundary-box {
            max-width: $rem315;
        }

        .recover-logo-img {
            object-fit: contain;
        }

        .recover-title-box {
            padding-top: $rem40;
            padding-bottom: $rem30;

            .recover-title {
                font-size: 20px;
                color: #FFFFFF;
                text-align: center;
                font-weight: bold;
            }
        }
    }

    .recover-alert-container {
        padding: 20px 15px;
        margin-top: 76px;
        border: 1px solid;
        border-radius: 5px;
        text-align: left;
        font-size: 16px;

        &.success {
            border-color: #49974E;
            background: #E5F7E1;
            color: #49974E;
        }
    }

    .recover-title-box {
        padding-top: $rem58;
        padding-bottom: $rem36;
    }

    .recover-input-box {
        position: relative;

        input {
            padding-left: $rem50;
            padding-right: $rem10;
            height: 3.25rem;
            background: transparent;
            color: white;
        }

        button,
        label {
            position: absolute;
            top: 0;

            width: $rem50;
            height: $rem50;
        }

        label {
            left: 0;

            img {
                width: 100%;
                height: 100%;
            }

            padding: .7rem !important;
        }

        button {
            right: 0;
            padding: 0;
            border: none;
            outline: none !important;
            background: transparent;
            display: flex;
            justify-content: center;
            align-items: center;
        }


    }

    .recover-alert-box {
        // background: rgb(255 0 0 / 50%);
        margin-top: 5px;
        border-radius: 5px;

        small {
            color: rgb(255, 0, 0);
        }
    }

    .recover-submit-box {
        padding-top: 1rem;
        margin-bottom: 50px;

        .btn_send {
            width: 100%;
            margin-top: 20px;
            border: none;
            border-radius: 5px;
            background-color: #00B2D2;
            color: white;
            padding-top: 20px;
            padding-bottom: 20px;
            cursor: pointer;
            font-weight: bold;
        }

        .btn_cancel {
            width: 100%;
            margin-top: 10px;
            border: 1px solid #00B2D2;
            border-radius: 5px;
            background-color: transparent;
            color: #00B2D2;
            padding-top: 20px;
            padding-bottom: 20px;
            cursor: pointer;
            font-weight: bold;
        }
    }

    .recover-sub-box {
        padding-top: $rem40;

        span {
            font-size: 1rem;
            color: #909090;
            font-weight: 400;

            a {
                color: $recover-purple;
            }
        }
    }
}

@media screen and (min-width: 768px) {
    .recover-container {
        .recover-back-box {
            padding-top: 3.75rem;
            padding-left: 3.75rem;
        }

        .recover-main-box {
            .recover-secundary-box {
                max-width: $rem360;
            }
        }

        .recover-alert-container {
            padding: 30px 20px;
            font-size: 16px;
            margin-top: 20px!important;
        }
    }
}