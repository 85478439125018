@import "core/colors";

body {
    background: var(--rblack);
}
img {
    user-drag: none !important;
    -webkit-user-drag: none !important;
    user-select: none !important;
    -moz-user-select: none !important;
    -webkit-user-select: none !important;
    -ms-user-select: none !important;
}

.avatar-image-container-60 {
    padding: 0 !important;
    max-width: 60px !important;
    max-height: 60px !important;
    width: 60px !important;
    height: 60px !important;


    img {
        max-width: 60px;
        max-height: 60px;
        width: 60px;
        height: 60px;
        border: 2px solid #fff;
        border-radius: 30px;
    }
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.pos-relative {
    position: relative;
}

.btn-cleared {
    background: transparent;
    border: none;
    outline: none !important;
    padding: 0;
}

.text-block-truncate-1 {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}

.text-block-truncate-2 {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.text-block-truncate-3 {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}

.line-height-15px {
    line-height: 15px;
}

.line-height-16px {
    line-height: 16px;
}

.line-height-17px {
    line-height: 17px;
}

.line-height-18px {
    line-height: 18px;
}

.line-height-19px {
    line-height: 19px;
}

.line-height-20px {
    line-height: 20px;
}

.swal2-container.swal2-top-end.swal2-backdrop-show {
    z-index: 3000;
}

.swal2-cancel {
    margin: 0 5px !important;
}

.swal2-icon.swal2-warning.swal2-icon-show {
    .swal2-icon-content {
        font-size: 55px;
    }
}

.swal2-title {
    font-size: 20px !important;
}

.swal2-html-container {
    font-size: 16px !important;
    color: #777;
    line-height: 20px !important;
}
