/*COLORS*/
$register-purple: #00B2D2;
$register-border-gray: #E0E0E0;
$register-909090: #909090;
/*COLORS*/

/*SIZE PX TO REM*/
$rem600: 37.5rem;
$rem580: 36.25rem;
$rem385: 24.0625rem;
$rem380: 23.75rem;
$rem360: 22.5rem;
$rem315: 19.6875rem;
$rem310: 19.375rem;
$rem270: 16.875rem;
$rem260: 16.25rem;
$rem245: 15.3125rem;
$rem240: 15rem;
$rem224: 14rem;
$rem209: 13.0625rem;
$rem180: 11.25rem;
$rem151: 9.4375rem;
$rem147_5: 9.21875rem;
$rem129: 8.0625rem;
$rem115: 7.199rem;
$rem110: 6.875rem;
$rem100: 6.25rem;
$rem92: 5.75rem;
$rem80: 5rem;
$rem71: 4.4375rem;
$rem65: 4.0625rem;
$rem59: 3.6875rem;
$rem58: 3.625rem;
$rem55_04: 3.44rem;
$rem50: 3.125rem;
$rem45: 2.8125rem;
$rem40: 2.5rem;
$rem36: 2.25rem;
$rem30: 1.875rem;
$rem25: 1.5625rem;
$rem24: 1.5rem;
$rem20: 1.25rem;
$rem18: 1.125rem;
$rem14: 0.875rem;
$rem10: 0.625rem;
/*SIZE PX TO REM*/

.register-animation {
    animation: dissapear .5s ease-in;
    animation-direction: normal;

    &.show {
        animation-direction: reverse;
    }

}

@keyframes dissapear {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}


.register-container {
    background: #1E1E1E;
    position: relative;
    overflow: auto;

    &.bg-step-2 {
        background: #fff;
    }

    .login-back-box {
        position: absolute;
        top: 0;
        left: 0;
        padding: 60px 0 0 60px;
        z-index: 99;



        a,
        button {
            background: transparent;
            padding: 0;
            height: $rem40;
            border: none;
            outline: none !important;
            color: $register-purple;
            font-size: $rem18;

            img {
                width: $rem40;
                height: $rem40;
            }
        }
    }

    .register-alert-box {
        margin-top: 5px;
        border-radius: 5px;

        small {
            color: #FB6868!important;
        }
    }

    .register-main-box {

        .register-logo {
            object-fit: contain;
        }

        .register-secundary-box {
            max-width: $rem315;

            .register-input-box {
                position: relative;

                &.second {
                    padding-top: 1.375rem;
                }

                input {
                    width: 100%;
                    border: 1px solid $register-border-gray;
                    height: $rem50;
                    outline: none !important;
                    border-radius: 5px;
                    color: white;
                    font-size: 1.125rem;
                    font-weight: 500;
                    padding-left: $rem50;
                    padding-right: $rem10;
                    -webkit-appearance: none;
                    -moz-appearance: none;
                    appearance: none;
                    background-color: transparent;

                    &::placeholder {
                        color: $register-909090;
                    }

                    &.psw {
                        padding-right: $rem50;
                    }
                }

                button,
                .label-icon {
                    position: absolute;
                    bottom: 0;

                    width: $rem50;
                    height: $rem50;
                }

                .label-icon {
                    left: 0;
                    margin: 0;
                    padding: .7rem;

                    img {
                        width: 100%;
                        height: 100%;
                    }
                }

                button {
                    right: 0;
                    padding: 0;
                    border: none;
                    outline: none !important;
                    background: transparent;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    img {
                        width: 100%;
                        height: 100%;
                        padding: 10px;
                    }
                }
            }

            .register-terms-box {
                padding-top: $rem20;

                .check-box {
                    max-width: $rem30;
                    max-height: $rem30;
                    width: $rem30;
                    height: $rem30;
                    position: relative;


                    label {
                        width: $rem30;
                        height: $rem30;
                        cursor: pointer;
                        position: absolute;
                        top: 0;
                        left: 0;
                        background: #fff;
                        border-radius: 5px;
                        border: 1px solid $register-border-gray;

                        &:after {
                            content: "";
                            width: 18px;
                            height: 10px;
                            position: absolute;
                            top: 40%;
                            left: 50%;
                            border: 3px solid #fff;
                            border-top: none;
                            border-right: none;
                            background: transparent;
                            opacity: 0;
                            -webkit-transform: translate(-50%, -50%) rotate(-45deg);
                            transform: translate(-50%, -50%) rotate(-45deg);
                        }

                        &:hover::after {
                            opacity: 0.3;
                        }
                    }

                    input[type=checkbox] {
                        visibility: hidden;
                    }

                    input[type=checkbox]:checked+label {
                        background: $register-purple;
                    }

                    input[type=checkbox]:checked+label:after {
                        opacity: 1;
                    }
                }

                .terms-box {
                    color: $register-909090;

                    a {
                        color: $register-purple;
                        text-decoration: underline;
                    }
                }
            }

            .register-submit-box {
                padding-top: 1rem;

                button {
                    height: $rem50;
                    width: 100%;
                    border-radius: 5px;
                    border: none;
                    outline: none !important;
                    background: $register-purple;
                    font-size: 1.125rem;
                    font-weight: 700;
                    color: #fff;
                }
            }

            .register-box {
                padding-top: 1rem;

                .register-btn-register {
                    width: 100%;
                    border: 1px solid #00B2D2;
                    border-radius: 5px;
                    background-color: transparent;
                    color: #00B2D2;
                    padding-top: 20px;
                    padding-bottom: 20px;
                    font-size: 17px;
                    text-align: center;
                    font-weight: bold;
                }
            }

            .register-alert-box {
                // background: rgba(255,0,0,0.5);
                margin-top: 5px;
                border-radius: 5px;

                &.alerted {
                    small {
                        color: rgba(255, 0, 0, 0.5);
                    }
                }
            }
        }

        .register-title-box {
            padding-top: 40px !important;

            .register-title {
                font-size: 20px;
                color: #FFFFFF;
                text-align: center;
                font-weight: bold;
            }
        }

        .register-sub-box {
            padding-top: $rem40;

            font-size: 1rem;
            color: $register-909090;
            font-weight: 400;

            a {
                color: $register-purple;
            }
        }

        .register-secundary-box {
            margin-top: 30px;
        }
    }

    .register-stepper-container {
        position: relative;
        padding-top: $rem151;



        .stepper-steps-box {
            background: #CDEBF4;
            height: $rem151;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;

            .stepper-logo {
                padding-top: $rem25;
                max-width: 100%;
                text-align: center;

                img {
                    width: $rem100;
                    height: $rem55_04;
                    object-fit: contain;
                }
            }

            .step-item {
                .step-number {
                    width: 30px;
                    height: 30px;
                    background: #fff;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 50%;
                    border: 2px solid $register-purple;
                    color: $register-purple;
                    font-size: $rem18;
                    margin-right: 12px;
                    margin-top: 10px;
                    margin-bottom: 10px;
                    font-weight: 700;
                }
            }


            .steps-bar-box {
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                background: $register-border-gray;
                height: 5px;

                .steps-bar {
                    height: 5px;
                    background: $register-purple;

                    &.step-0 {
                        width: 33%;
                    }

                    &.step-1 {
                        width: 66%;
                    }

                    &.step-2 {
                        width: 100%;
                    }
                }
            }
        }

        &.plans {
            padding-top: $rem110;

            .stepper-steps-box {
                height: $rem110;

                .stepper-logo {
                    padding-top: 0;
                }
            }


            .register-plans-title-box {
                font-size: $rem20;
                font-weight: 700;
            }

            .plans-disclaimer {
                padding-top: $rem45;
            }

            .plans-container {

                .plan-item-container {
                    margin-top: $rem40;
                    max-width: $rem240;
                    min-width: $rem240;
                    width: $rem240;

                    .plan-info-box {
                        background: #F8F9FA;
                        border-radius: $rem10;
                        max-height: $rem315;
                        width: 100%;
                        height: $rem315;

                        padding-top: $rem10;
                        padding-bottom: $rem20;

                        .plan-img-icn {
                            width: $rem65;
                            height: $rem59;
                        }

                        .plan-princing-box {
                            color: #595959;
                            font-size: $rem20;
                            font-weight: 700;
                            text-align: center;
                            padding: 4px 0;
                        }

                        .plan-name-box {
                            font-size: 1rem;
                            font-weight: 700;

                            text-align: center;
                            color: #07B873;
                            padding: 4px 0;

                            &.basic {
                                color: #A786F7;
                            }

                            &.premium {
                                color: #DBC508;
                            }

                            &.hotsale {
                                color: #df2630;
                            }
                        }

                        .plan-details-box {
                            ul {
                                padding: 0;
                                list-style: none;
                                text-align: center;
                                line-height: 1rem;
                                color: #535353;
                                font-size: $rem14;
                                padding-top: 0;
                                margin: 0;
                            }
                        }
                    }

                    .plan-action {
                        height: $rem45;
                        margin-top: 1rem;

                        button {
                            height: 100%;
                            width: 100%;
                            border: 1px solid $register-purple;
                            border-radius: 1.40625rem;
                            font-weight: 700;

                            font-size: 1rem;
                            color: $register-purple;
                            background: transparent;
                            outline: none !important;

                            &.popular {
                                background: $register-purple;
                                color: #fff;
                            }
                        }
                    }
                }
            }
        }

        .step-container {
            max-width: $rem315;

            .step1-box {
                .step-input-title-box {
                    padding-bottom: $rem20;
                    padding-top: $rem20;
                    font-size: $rem20;
                }

                .step-input-box {
                    position: relative;


                    input {
                        width: 100%;
                        height: $rem50;
                        border: 1px solid $register-border-gray;
                        border-radius: 5px;
                        padding: 0 1rem;
                        -webkit-appearance: none;
                        -moz-appearance: none;
                        appearance: none;
                    }

                    &.lada {
                        input {
                            padding-left: calc(7.199rem + .5rem);
                        }

                        .lada-select {
                            border: 1px solid $register-border-gray;
                            background: #F8F9FA;
                            color: $register-909090;
                            border-top-left-radius: 5px;
                            border-bottom-left-radius: 5px;

                            &::-ms-expand {
                                display: none;
                            }

                            position: absolute;
                            top: 0;
                            left: 0;
                            height: $rem50;
                            width: $rem115;

                            background-position: 0;
                            background-size: $rem50;

                            background-repeat: no-repeat;
                            padding-left: $rem50;

                            img {
                                height: 100%;
                                width: 100%;
                            }

                            option {
                                height: $rem50;
                                width: $rem50;

                                img {
                                    height: 100%;
                                    width: 100%;
                                }
                            }
                        }

                        &:after {
                            content: "";
                            display: block;
                            position: absolute;
                            top: $rem25;
                            left: calc(7.199rem - 1.375rem);
                            width: 0;
                            height: 0;
                            border-color: transparent transparent transparent $register-909090;
                            border-style: solid;
                            border-width: 7px;
                            transform: rotate(90deg);
                            pointer-events: none;
                        }
                    }

                    &.slug-selector {
                        input {
                            padding-left: $rem180;
                            color: $register-purple;
                            font-weight: 700;
                        }

                        label {
                            background: #F5F6F6;
                            border-radius: 5px;
                            position: absolute;
                            height: 50px;
                            margin: 0;
                            color: $register-909090;

                            display: flex;
                            align-items: center;
                            justify-content: center;
                            font-size: 13px;
                            padding-left: 8px;
                            padding-right: 7px;

                        }
                    }

                    &.icned {
                        input {
                            padding-left: $rem50;
                        }

                        label {
                            position: absolute;
                            top: 0;
                            left: 0;
                            margin: 0;
                            width: $rem50;
                            height: $rem50;

                            img {
                                width: 100%;
                                height: 100%;
                            }
                        }
                    }

                    &.with-btn-map {
                        input {
                            width: calc(100% - 100px);
                        }

                        button {
                            height: 50px;
                            width: 90px;
                            position: absolute;
                            right: 0;
                            bottom: 0;
                            border: 1px solid #7F60EA;
                            border-radius: 5px;
                            background: transparent;

                            img {
                                position: absolute;
                                top: 50%;
                                left: 50%;
                                transform: translate(-50%, -50%);
                            }
                        }
                    }

                    small {
                        color: rgba(255, 0, 0, 0.5);
                    }
                }

                .step-select-box {
                    position: relative;

                    select {
                        padding: 0 $rem20;
                        width: 100%;
                        height: $rem50;
                        border: 1px solid $register-purple;
                        border-radius: 5px;
                        color: $register-purple;

                        // option:not(:first-of-type) {
                        //     color: #535353;
                        // }
                        // option:first-of-type {
                        //     color: #535353;
                        // }
                    }

                    select:required:invalid {
                        color: $register-909090;
                    }

                    label {
                        height: 100%;
                        position: absolute;
                        top: 0;
                        right: 0;
                        pointer-events: none;

                        img {
                            width: $rem50;
                            height: 100%;
                        }
                    }
                }

                .step-option-picker-box {
                    .picker-item {
                        text-align: center;

                        .picker-col {
                            border-radius: 5px;
                            border: 1px solid $register-border-gray;
                            cursor: pointer;

                            &.invalid-picker {
                                border: 1px solid red;
                            }

                            img {
                                max-width: 89px;
                                max-height: 89px;
                                // filter: grayscale(100%);
                            }

                            p {
                                color: $register-909090;
                                font-size: 1rem;
                                font-weight: 700;
                                text-align: center;
                                min-width: $rem147_5;
                                line-height: 18px;
                            }
                        }


                        &.selected {
                            .picker-col {
                                border: 1px solid #5162E7;

                                img {
                                    // filter: unset;
                                }

                                p {
                                    color: #535353;
                                }
                            }

                        }
                    }
                }
            }
        }
    }

    .register-btn-box {

        max-width: $rem245;

        &.double {
            max-width: calc(#{$rem151} + .5rem);
        }

        .register-btn {
            width: 100%;
            height: $rem50;
            border: 1px solid $register-purple;
            background: $register-purple;
            color: #fff;
            font-size: $rem18;
            font-weight: 700;
            border-radius: 5px;

            &.invert {
                background: transparent;
                color: $register-purple;
            }
        }
    }

}

.success-input {

    select:not(.lada-select),
    input {
        border: 1px solid $register-border-gray !important;
        background: #CDEBF4 !important;
    }
}

.fail-input {

    label.slug-view,
    select,
    input {
        border: 1px solid red !important;
    }

    select:not(.lada-select),
    input {
        background: #ffebeb !important;
    }
}

.alerted {
    small {
        color: rgba(255, 0, 0, 0.5);
    }
}

.register-plans-box {
    height: calc(100% - 6.875rem) !important;
}

.z-99 {
    z-index: 99;
}

@media screen and (min-width: 768px) {
    .register-plans-box {
        height: 100% !important;
    }

    .register-container {
        .register-main-box {
            .register-secundary-box {
                max-width: $rem360;
            }
        }

        .register-btn-box {
            max-width: $rem245 !important;

            .register-btn {
                width: 100%;
            }
        }


        .register-stepper-container {

            padding-top: $rem151;

            &.plans {
                padding-top: $rem151;

                .stepper-steps-box {
                    height: $rem151;
                }

                .register-plans-title-box {
                    font-size: $rem24;
                }

                .plans-disclaimer {
                    padding-top: $rem65;
                }

                .plans-container {

                    .plan-item-container {
                        margin-top: $rem50;
                        max-width: $rem310;
                        min-width: $rem310;
                        width: $rem310;

                        .plan-info-box {
                            border-radius: $rem10;
                            max-height: $rem380;
                            height: $rem380;

                            padding-top: $rem10;
                            padding-bottom: $rem20;

                            .plan-img-icn {
                                width: $rem100;
                                height: $rem92;
                            }

                            .plan-princing-box {
                                font-size: $rem30;
                                padding: 6px 0;
                            }

                            .plan-name-box {
                                font-size: $rem24;
                                padding: 6px 0;
                            }

                            .plan-details-box {
                                ul {
                                    line-height: $rem20;
                                    font-size: 1rem;
                                    padding-top: $rem25;
                                }
                            }
                        }

                        .plan-action {
                            height: $rem50;
                            margin-top: $rem50;

                            button {
                                border-radius: $rem25;

                                font-size: $rem18;
                            }
                        }
                    }
                }
            }

            .stepper-steps-box {

                .steps-height {
                    height: 100%;
                }

                .stepper-logo {
                    padding-top: 0;
                    padding-left: $rem80;
                    max-width: $rem224;
                    position: relative;
                    left: 0;

                    img {
                        width: $rem129;
                        height: $rem71;
                        object-fit: contain;
                    }
                }

                .step-item {
                    max-width: $rem270;

                    .step-border-box {
                        border-bottom: 5px solid $register-border-gray;

                        &.stepped {
                            border-bottom: 5px solid $register-purple;

                        }

                        span.d-flex {
                            flex-direction: column;
                            font-size: 12px;
                        }
                    }
                }
            }

            .step-container {
                max-width: $rem580;

                .step1-box {
                    min-height: $rem600;

                    .step-input-title-box {
                        padding-bottom: $rem20;
                        padding-top: $rem30;
                        font-size: $rem24;
                    }

                    .step-input-box {

                        &.slug-selector {
                            input {
                                padding-left: 13.25rem;
                            }

                            label {
                                font-size: 18px;
                                padding-left: 20px;
                                padding-right: 20px;
                            }
                        }

                    }

                    .step-option-picker-box {
                        .picker-item {
                            .picker-col {

                                img {
                                    max-width: $rem115;
                                    max-height: $rem115;
                                }

                                p {
                                    font-size: $rem18;
                                }
                            }
                        }
                    }
                }

            }
        }
    }


    img {
        user-drag: none !important;
        user-select: none !important;
        -moz-user-select: none !important;
        -webkit-user-drag: none !important;
        -webkit-user-select: none !important;
        -ms-user-select: none !important;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    input[type=number] {
        -moz-appearance: textfield;
    }

}

@media screen and (min-width: 1000px) {
    .register-container {
        .register-main-box {
            .register-secundary-box {
                max-width: $rem360;
            }
        }

        .register-btn-box {
            max-width: $rem245 !important;

            .register-btn {
                width: 100%;
            }
        }


        .register-stepper-container {

            padding-top: $rem151;

            &.plans {
                padding-top: $rem151;

                .stepper-steps-box {
                    height: $rem151;
                }

                .register-plans-title-box {
                    font-size: $rem24;
                }

                .plans-disclaimer {
                    padding-top: $rem65;
                }

                .plans-container {

                    .plan-item-container {
                        margin-top: $rem50;
                        max-width: $rem310;
                        min-width: $rem310;
                        width: $rem310;

                        .plan-info-box {
                            border-radius: $rem10;
                            max-height: $rem380;
                            height: $rem380;

                            padding-top: $rem10;
                            padding-bottom: $rem20;

                            .plan-img-icn {
                                width: $rem100;
                                height: $rem92;
                            }

                            .plan-princing-box {
                                font-size: $rem30;
                                padding: 6px 0;
                            }

                            .plan-name-box {
                                font-size: $rem24;
                                padding: 6px 0;
                            }

                            .plan-details-box {
                                ul {
                                    line-height: $rem20;
                                    font-size: 1rem;
                                    padding-top: $rem25;
                                }
                            }
                        }

                        .plan-action {
                            height: $rem50;
                            margin-top: $rem50;

                            button {
                                border-radius: $rem25;

                                font-size: $rem18;
                            }
                        }
                    }
                }
            }

            .stepper-steps-box {

                .steps-height {
                    height: 100%;
                }

                .stepper-logo {
                    padding-top: 0;
                    padding-left: $rem80;
                    max-width: $rem224;
                    position: absolute;
                    left: 0;

                    img {
                        width: $rem129;
                        height: $rem71;
                        object-fit: contain;
                    }
                }

                .step-item {
                    max-width: $rem270;

                    .step-border-box {
                        border-bottom: 5px solid $register-border-gray;

                        &.stepped {
                            border-bottom: 5px solid $register-purple;
                        }

                        span.d-flex {
                            flex-direction: row;
                            font-size: inherit;
                        }
                    }
                }
            }

            .step-container {
                max-width: $rem580;

                .step1-box {
                    min-height: $rem600;

                    .step-input-title-box {
                        padding-bottom: $rem20;
                        padding-top: $rem30;
                        font-size: $rem24;
                    }

                    .step-input-box {

                        &.slug-selector {
                            input {
                                padding-left: 13.25rem;
                            }

                            label {
                                font-size: 18px;
                                padding-left: 20px;
                                padding-right: 20px;
                            }
                        }

                    }

                    .step-option-picker-box {
                        .picker-item {
                            .picker-col {

                                img {
                                    max-width: $rem115;
                                    max-height: $rem115;
                                }

                                p {
                                    font-size: $rem18;
                                }
                            }
                        }
                    }
                }

            }
        }
    }

}

@media screen and (min-width: 1200px) {
    .justify-content-xl-center {
        justify-content: center !important;
    }
}