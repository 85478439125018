.Loader-container {
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    position: absolute;
    z-index: 20;
    background: var(--rgray-darker);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .Loader-image-container {

        img {
            width: 207px;
            height: 80px;
            object-fit: contain;
        }
    }

    .spinner {
        // margin: 100px auto;
        width: auto;
        height: 80px;
        text-align: center;
        font-size: 10px;

        div {
            background-color: var(--rpink);
            height: 100%;
            width: 6px;
            display: inline-block;
            margin: 0 3px 0 0;
            -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
            animation: sk-stretchdelay 1.2s infinite ease-in-out;
        }

        .rect2 {
            -webkit-animation-delay: -1.1s;
            animation-delay: -1.1s;
        }

        .rect3 {
            -webkit-animation-delay: -1.0s;
            animation-delay: -1.0s;
        }

        .rect4 {
            -webkit-animation-delay: -0.9s;
            animation-delay: -0.9s;
        }

        .rect5 {
            -webkit-animation-delay: -0.8s;
            animation-delay: -0.8s;
        }

        .rect6 {
            -webkit-animation-delay: -0.7s;
            animation-delay: -0.7s;
        }

        .rect7 {
            -webkit-animation-delay: -0.6s;
            animation-delay: -0.6s;
        }

        .rect8 {
            -webkit-animation-delay: -0.5s;
            animation-delay: -0.5s;
        }

        .rect9 {
            -webkit-animation-delay: -0.4s;
            animation-delay: -0.4s;
        }

        @-webkit-keyframes sk-stretchdelay {
            0%, 40%, 100% { -webkit-transform: scaleY(0.4) }
            20% { -webkit-transform: scaleY(1.0) }
        }

        @keyframes sk-stretchdelay {
            0%, 40%, 100% {
                transform: scaleY(0.4);
                -webkit-transform: scaleY(0.4);
            }  20% {
                transform: scaleY(1.0);
                -webkit-transform: scaleY(1.0);
            }
        }
    }


}
