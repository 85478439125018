.Error-container {
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    position: absolute;
    z-index: 20;
    background: var(--rgray-darker);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .Error-image-container {

        img {
            width: 207px;
            height: 80px;
            object-fit: contain;
        }
    }

    .Error-disclaimer-container {
        text-align: center;
        padding: 20px 14px;

        font-size: 16px;
        font-weight: bold;
        max-width: 700px;
        color: var(--rgray-placeholder);
    }
}
