$login-purple: #00b2d2;
$rem360: 22.5rem;
$rem315: 19.6875rem;
$rem58: 3.625rem;
$rem50: 3.125rem;
$rem40: 2.5rem;
$rem36: 2.25rem;
$rem30: 1.875rem;
$rem18: 1.125rem;
$rem10: 0.625rem;


.login-animation {
    animation: dissapear .5s ease-in;
    animation-direction: normal;

    &.show {
        animation-direction: reverse;
    }

}

@keyframes dissapear {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

.login-container {
    background: #1E1E1E;
    position: relative;
    ;

    .login-back-box {
        position: absolute;
        top: 0;
        left: 0;
        padding-top: 1rem;
        padding-left: 1rem;
        z-index: 99;


        a,
        button {
            background: transparent;
            padding: 0;
            height: $rem40;
            border: none;
            outline: none !important;
            color: $login-purple;
            font-size: $rem18;

            img {
                width: $rem40;
                height: $rem40;
            }
        }
    }

    .login-main-box {
        max-width: $rem315;


        .login-logo-img {
            object-fit: contain;
        }

        .login-title-box {
            padding-top: $rem58;
            padding-bottom: $rem36;

            .login-title {
                font-size: 20px;
                color: #FFFFFF;
                text-align: center;
                font-weight: bold;
            }

        }
    }

    .login-title-box {
        padding-top: $rem58;
        padding-bottom: 30px !important;
    }

    .login-input-box {
        position: relative;

        input {
            width: 100%;
            border: 1px solid #E0E0E0;
            height: $rem50;
            outline: none !important;
            border-radius: 5px;
            color: white;
            background-color: transparent !important;
            font-size: 1.125rem;
            font-weight: 500;
            padding-left: $rem50;
            padding-right: $rem10;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;

            &::placeholder {
                color: #909090;
            }

            &.psw {
                padding-right: $rem50;
            }
        }

        button,
        label {
            position: absolute;
            top: 0;

            width: $rem50;
            height: $rem50;
        }

        label {
            left: 0;

            img {
                margin: 10px;
                width: 30px;
                height: 30px;
            }
        }

        button {
            right: 0;
            padding: 0;
            border: none;
            outline: none !important;
            background: transparent;
            display: flex;
            justify-content: center;
            align-items: center;

            img {
                width: 100%;
                height: 100%;
                padding: 10px;
            }
        }


    }

    .login-alert-box {
        // background: rgb(255 0 0 / 50%);
        margin-top: 5px;
        border-radius: 5px;

        small {
            color: #FB6868!important;
        }
    }

    .login-submit-box {
        padding-top: 20px !important;

        button {
            height: $rem50;
            width: 100%;
            border-radius: 5px;
            border: none;
            outline: none !important;
            background: $login-purple;
            font-size: 1.125rem;
            font-weight: 700;
            color: #fff;
        }
    }

    .login-forgot-password {
        margin-top: 30px;
        text-align: center;

        span {
            font-size: 16px;
            color: #F48DB8;
            text-decoration: underline;
            cursor: pointer;
        }
    }

    .login-sub-box {
        padding-top: $rem40;

        span {
            font-size: 1rem;
            color: #909090;
            font-weight: 400;

            a {
                color: $login-purple;
            }
        }

        .register-btn {
            width: 100%;
            margin-top: 20px;
            border: 1px solid #00B2D2;
            border-radius: 5px;
            background-color: transparent;
            color: #00B2D2;
            padding-top: 20px;
            padding-bottom: 20px;
            cursor: pointer;
            font-weight: bold;
        }

        margin-bottom: calc( 70px - 16px );
    }



}

@media screen and (min-width: 768px) {
    .login-container {
        .login-back-box {
            padding-top: 3.75rem;
            padding-left: 3.75rem;
        }

        .login-main-box {
            max-width: $rem360;
        }
    }
}

a {
    text-decoration: none !important;
}