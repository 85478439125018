.Header-container {
    background: #00B2D2;
    min-height: 60px;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    z-index: 30;
    display: grid;
    grid-template-columns: 1fr 1fr;

    .img-logo-container {
        padding-left: 20px;
        padding-bottom: 20px;
        padding-top: 20px;
    }

    .user-container {
        align-self: center;
        text-align: end;
        margin-right: 20px;

        .placeholderUser {
            margin-right: 10px;
            width: 30px;
            height: 30px;
        }

        .labelUser {
            font-size: 13px;
            color: #FFFFFF;
            font-weight: bold;
        }
    }

    .buttons-action-container {
        z-index: 99;
        display: grid;
        grid-template-columns: 1fr 1fr;

        .single-button-container {
            cursor: pointer;

            .icn-float {
                width: 25px;
            }
        }
    }
}