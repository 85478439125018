.ModalDefault-main-box{

    .modal-dialog{
        .modal-content{
            border-radius: 8px;

            .modal-header{
                border-bottom: none;
            }

        }
    }
}