.SearchResults-container {

    .SearchResults-results-videos-container {

        padding: 14px 0;

        .SearchResults-results-video-item {
            background: var(--rgray-darker);
            z-index: 2;
            border-radius: 5px;
            margin-bottom: 20px;
            cursor: pointer;
            z-index: 25;

            .SearchResults-details-container {
                padding: 10px;
                .thumbnail-container {
                    padding: 0;
                    width: 110px;
                    height: 62px;
                    max-width: 110px;
                    max-height: 62px;

                    img {
                        width: 110px;
                        height: 62px;
                        max-width: 110px;
                        max-height: 62px;
                        object-fit: cover;
                    }
                }

                .video-name,
                .video-author {
                    font-size: 16px;
                    color: #fff;
                }

                .video-name {
                    font-weight: bold;
                }
            }

            .SearchResults-action-container {
                background: var(--rgray-dark);
                border-bottom-left-radius: 5px;
                border-bottom-right-radius: 5px;
                height: 45px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                color: var(--rcyan);
                font-weight: 600;
                font-size: 16px;
            }

        }
    }
}
