@import "~bootstrap/scss/bootstrap";

.App {
  height: 100vh;
  background: var(--rblack);
  position: relative;
  overflow: auto;

  .App-main {
    max-width: 700px;
    margin: 0 auto;
    // height: 100%;
    padding: 70px 0;

    .playingNow-container {
      margin-top: 30px;
      cursor: pointer;

      .playinNow {
        background: #4A4A4A;
        border-radius: 23px;
        margin-left: 50px;
        margin-right: 50px;
        display: grid;
        grid-template-columns: 50px 1fr;

        .icn_playing_now {
          height: 30px;
          width: 30px;
          margin-left: 15px;
          margin-top: 10px;
          margin-bottom: 10px;
          margin-right: 10px;
        }

        .label_playing_now {
          width: 200px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          align-self: center;
          font-size: 16px;
          color: #FFFFFF;
        }
      }

    }
  }
}

.title-modal {
  margin-top: calc(40px - 48px);
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  text-align: center;
  color: #4a4a4a;
}

.video-pre {
  border-radius: 5px;
  background: #eff1f2;
  border-radius: 5px;
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 40px;

  .icn_placeholder {
    width: 60px;
  }

  .name-song {
    margin-top: 20px;
    color: #4a4a4a;
    font-size: 16px;
    font-weight: 700;
    line-height: 19px;
    margin-bottom: 1px;
  }

  .author-song {
    margin-bottom: 20px;
    color: #4a4a4a;
    font-family: "SF Pro Text";
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
  }
}

.rate {
  text-align: -webkit-center;
  margin-top: 25px;
  margin-bottom: 30px;


  .container-rates {
    width: 80%;

    .icn_rate {
      width: 53px;
    }
  }
}

.dislike {

  .item-dislike {
    .icn_select {
      width: 40px;
      margin-right: 12px;
      margin-left: 22px;
    }

    .label-select {
      color: #4a4a4a;
      font-size: 16px;
      font-weight: 400;
      line-height: 19px;
    }
  }
}

.submit-dislike {
  margin-top: 20px;
  margin-left: calc(20px - 16px);
  margin-right: calc(20px - 16px);

  .btn-send {
    width: 100%;
    border-radius: 5px;
    background: #f48db8;
    background-blend-mode: normal;
    color: #ffffff;
    font-size: 18px;
    font-weight: 700;
    line-height: 19px;
    text-align: center;
    padding-top: 18px;
    padding-bottom: 18px;
  }
}

.float-btn {
  z-index: 99;
  position: absolute;
  top: 10px;
  right: -35%;
  cursor: pointer;
  text-align: center;

  .icn-float {
    width: 25px;
  }

  .text-now {
    color: black;
    font-size: 13px;
  }
}

.modal-black {
  .modal-content {
    background-color: #1E1E1E;
    ;
  }
}


@media (max-width: 600px) {

  .float-btn {
    .text-now {
      font-size: 10px;
    }
  }
}