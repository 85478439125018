.SearchBox-container {
    padding-bottom: 40px;

    .SearchBox-establishment-container {
        display: flex;
        align-items: center;
        padding: 30px 0;

        .establishment-details-row {
            line-height: 20px;

            .establishment-title,
            .establishment-subtitle {
                color: #fff;
            }

            .establishment-title {
                font-size: 18px;
                font-weight: bold;
            }

            .establishment-subtitle {
                font-size: 16px;
                font-weight: light;
            }
        }
    }

    .SearchBox-title-container {
        font-size: 34px;
        font-weight: bold;
        color: #fff;
        line-height: 40px;

        span {
            color: var(--rcyan);
        }
    }

    &.isFixed {
        .SearchBox-inputs-container {
            position: fixed;
            top: 60px;
            left: 50%;
            transform: translateX(-50%);
            max-width: 700px;
            width: 100%;
            z-index: 40;
            background: var(--rblack);
            padding-right: calc(var(--bs-gutter-x) * .5);
            padding-left: calc(var(--bs-gutter-x) * .5);
        }
    }
    .SearchBox-inputs-container {

        padding: 35px 0px 20px 0px;

        .SearchBox-input-box {
            position: relative;
            max-height: 48px;
            margin-bottom: 10px;

            label,
            button {
                position: absolute;
                width: 48px;
                height: 48px;
                top: 0;

                img {
                    width: 48px;
                    height: 48px;
                }
            }

            label {
                left: 0;
            }

            button {
                right: 0;
            }

            input {
                width: 100%;
                height: 48px;
                background: transparent;
                border: 1px solid var(--rgray-border);
                border-radius: 5px;
                padding: 0 48px;
                outline: none !important;
                color: #fff;
                font-weight: 600;
                caret-color: var(--rpink);

                &::placeholder {
                    color: var(--rgray-placeholder);
                }
            }
        }

        .SearchBox-select-container {

            position: relative;

            .select-label {
                position: absolute;
                width: 48px;
                height: 48px;
                top: 0;

                img {
                    width: 48px;
                    height: 48px;
                }
            }

            .SearchBox-select-container {
                .SearchBox-select-container__control {
                    background: transparent !important;
                    min-height: 48px !important;
                    padding-left: 48px !important;
                    border: 1px solid var(--rgray-border) !important;
                    box-shadow: none !important;

                    .SearchBox-select-container__value-container {
                        padding-left: 0 !important;

                        div[class^="css-"][class$="-placeholder"],
                        div[class^=" css-"][class$="-placeholder"] {
                            color: var(--rgray-placeholder);
                            font-size: 17px !important;
                            font-weight: 600 !important;
                        }

                        input {
                            font-size: 17px !important;
                            font-weight: 600 !important;
                            color: #fff !important;
                            caret-color: var(--rpink) !important;
                        }

                        .SearchBox-select-container__multi-value {
                            background: transparent !important;
                            border: 1px solid var(--rcyan) !important;
                            border-radius: 5px !important;
                            color: var(--rcyan) !important;

                            .SearchBox-select-container__multi-value__label {
                                color: var(--rcyan) !important;

                            }

                            .SearchBox-select-container__multi-value__remove {
                                svg {
                                    fill: var(--rpink) !important;
                                }
                            }
                        }
                    }

                    .SearchBox-select-container__indicators {
                        svg {
                            fill: var(--rcyan) !important;
                        }
                    }
                }

                .SearchBox-select-container__menu {
                    background: var(--rgray-darker) !important;
                    z-index: 40 !important;

                    .SearchBox-select-container__menu-list {

                        .SearchBox-select-container__option {

                            color: var(--rcyan) !important;
                        }

                    }
                }
            }
        }

        .SearchBox-disclaimer-container {
            font-size: 13px;
            color: var(--rcyan);
            padding-top: 20px;
        }
    }

    .SearchResults-result-label-container {
        font-size: 17px;
        font-weight: bold;
        color: #fff;
        padding-top: 35px;
        padding-bottom: 20px;
    }

    .SearchResults-result-tags-container {


        .SearchResults-result-tag-item {
            border: 1px solid var(--rcyan);
            border-radius: 5px;

            display: flex;
            cursor: pointer;
            padding: 0;

            &:hover {
                border: 1px solid rgba(249,120,120,0.7);
                background: rgba(249,120,120,0.7);
                color: #fff;
                div {
                    svg {
                        fill: #fff;
                    }
                }
            }

            max-width: fit-content;
            padding: 4px 0px 4px 10px;
            margin: 0 10px 10px 0;
            color: var(--rcyan);
            font-size: 14px;
            font-weight: 600;
            div {
                display: inline-block;
                max-width: 40px;
                max-height: 30px;
                padding: 0px 5px;
                color: var(--rpink);
            }
        }
    }

    .SearchBox-footer-btn {
        height: 54px;
        font-size: 16px;
        font-weight: 600;
        color: #fff;
        background: var(--rcyan);
        z-index: 26;
        position: fixed;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        max-width: 700px;
        cursor: pointer;

        img {
            height: 54px;
            width: 30px;
            object-fit: cover;
        }
    }

    .SearchBox-noresults-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        z-index: 20;
        background: rgba(30,30,30,0.62);

        img {
            width: 100px;
            height: 100px;
        }

        p {
            font-size: 16px;
            font-weight: bold;
            color: var(--rgray-placeholder);
            text-align: 100%;
        }
    }

}
